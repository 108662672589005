import React, { useState } from "react";
import { Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import "./flight.css";
import { FaArrowRightLong } from "react-icons/fa6";
import { LuPlaneTakeoff, LuPlaneLanding } from "react-icons/lu";
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowDropdownCircle } from "react-icons/io";

function FlightCardComp({
  fly,
  index,
  chargeDetails,
  setChargeDetails,
  legDescription,
}) {
  const [isDetailed, setIsDetailed] = useState(false);
  const [tabKey, setTabKey] = useState("flight_info");
  const [tabIndex, setTabIndex] = useState();
  const navigate = useNavigate();

  const handleDetails = (index) => {
    setTabIndex(index);
    setIsDetailed(!isDetailed);
    if (isDetailed) {
      setTabIndex(null);
    }
  };

  const timeCalc = (time) => {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    if (hours === 0) return minutes + "min";
    return hours + "hr " + minutes + "min";
  };

  const removeCountryCode = (time) => {
    // make it just time not country code
    return time.replace(/.*?(\d{2}:\d{2}).*/, "$1");
  };

  const priceFormat = (price) => {
    // make the thousand with ","
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const priceCalc = (price) => {
    // remove the commission from the price

    let total = 0;
    if (chargeDetails.commission_Type == "percent") {
      total = price - (price * chargeDetails.commission_percentage) / 100;
    } else {
      total = price - chargeDetails.commission_percentage;
    }
    // make the thousand with ","

    return parseFloat(total)
      .toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const schd = JSON.parse(localStorage.getItem("schD"));

  let customFlightFilter = [];
  // filteredAirlines.map((item) => {
  //   item?.itinerary_leg_descs.filter((leg) => {
  //     customFlightFilter.push(leg?.departure_location);
  //   });
  // });
  schd?.map((item) => {
    customFlightFilter.push(item?.OriginLocation.LocationCode);
  });
  const generateComp = (schedules) => {
    // let customDepartureInfo = [];
    // let customArrivalInfo = [];
    // customFlightFilter?.map((item) => {
    //   customDepartureInfo?.push(
    //     schedules.find((schedule) => {
    //       if (schedule?.departure_airport === item) {
    //         return schedule;
    //       }
    //     })
    //   );
    //   customArrivalInfo?.push(
    //     schedules.find((schedule) => {
    //       if (schedule?.arrival_airport === item) {
    //         return schedule;
    //       }
    //     })
    //   );
    // });

    // console.log(customDepartureInfo, customArrivalInfo, "customDepartureInfo");
    const dacToJfkStart = schedules.findIndex(
      (flight) => flight.departure_airport === customFlightFilter[0]
    );
    const dacToJfkEnd =
      schedules.findIndex(
        (flight) => flight.arrival_airport === customFlightFilter[1]
      ) + 1;

    const jfkToDacStart = schedules.findIndex(
      (flight) => flight.departure_airport === customFlightFilter[1]
    );
    const jfkToDacEnd =
      schedules.findIndex(
        (flight) => flight.arrival_airport === customFlightFilter[0]
      ) + 1;

    // Slicing the arrays
    const departureHereToThere = schedules.slice(dacToJfkStart, dacToJfkEnd);
    const departureThereToHere = schedules.slice(jfkToDacStart, jfkToDacEnd);

    let departureElapsedTime = departureHereToThere
      .map(
        (item) =>
          parseInt(item.elapsed_time) +
          parseInt(item.layover_time ? item.layover_time : 0)
      )
      .reduce((a, b) => a + b, 0);

    let arrivalElapsedTime = departureThereToHere
      .map(
        (item) =>
          parseInt(item.elapsed_time) +
          parseInt(item.layover_time ? item.layover_time : 0)
      )
      .reduce((a, b) => a + b, 0);

    return (
      <div className="">
        <>
          <div
            className=" round_trip_border"
            style={{
              borderLeft: "1px solid #e5e5e5",
              borderRight: "1px solid #e5e5e5",
              padding: "10px ",
            }}
          >
            <Row>
              <Col lg={3}>
                {departureHereToThere[0]?.departure_airport}
                <span className="d-block">
                  {departureHereToThere[0]?.departure_date}{" "}
                </span>
                <span className="fs-12">
                  {removeCountryCode(departureHereToThere[0]?.departure_time)}
                </span>
                <span className="fs-12"> (Local time)</span>
              </Col>
              <Col md={6} className="text-center fs-14 mt-4 mt-md-0">
                <div
                  className="d-sm-flex align-items-center justify-content-center"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <span className="d-inline-flex align-items-center">
                    <img
                      src="/assets/img/clock.svg"
                      alt="clock"
                      className="img-fluid service-icon mr-1"
                    />
                    {timeCalc(departureElapsedTime)}
                    &nbsp; Duration
                  </span>

                  <span className="d-inline-flex align-items-center ">
                    &nbsp;
                    <span className="text-muted">|</span>
                    &nbsp;
                    <img
                      src="/assets/img/economy.svg"
                      alt=""
                      className="img-fluid service-icon mr-1"
                    />
                    {fly.passenger_infos[0].cabin_class == "Y"
                      ? "Economy"
                      : fly.passenger_infos[0].cabin_class == "C"
                      ? "Business"
                      : fly.passenger_infos[0].cabin_class == "S"
                      ? "Premium Economy"
                      : fly.passenger_infos[0].cabin_class == "J"
                      ? "Premium Business"
                      : fly.passenger_infos[0].cabin_class == "F"
                      ? "First Class"
                      : fly.passenger_infos[0].cabin_class == "P"
                      ? "Premium First"
                      : fly.passenger_infos[0].cabin_class}
                  </span>
                </div>
                <div className="two-dots my-3 text-muted position-relative border-top">
                  <span className="flight-service">
                    <img
                      src="/assets/img/airplane.svg"
                      alt=""
                      className="img-fluid service-icon service-icon_flight mr-1"
                    />
                    <span className="type-text px-2 position-relative">
                      Flight
                    </span>
                  </span>
                </div>
              </Col>
              <Col lg={3}>
                {
                  departureHereToThere[departureHereToThere.length - 1]
                    ?.arrival_airport
                }{" "}
                <span className="d-block">
                  {
                    departureHereToThere[departureHereToThere.length - 1]
                      ?.arrival_date
                  }
                </span>{" "}
                <span className="fs-12">
                  {removeCountryCode(
                    departureHereToThere[departureHereToThere.length - 1]
                      ?.arrival_time
                  )}
                </span>
                <span className="fs-12"> (Local time)</span>
              </Col>
            </Row>
          </div>
          {/* second row */}
          <div
            className=" round_trip_border  "
            style={{
              borderLeft: "1px solid #e5e5e5",
              borderRight: "1px solid #e5e5e5",
              padding: "10px ",
            }}
          >
            <Row>
              <Col lg={3}>
                {departureThereToHere[0]?.departure_airport}
                <span className="d-block">
                  {departureThereToHere[0]?.departure_date}
                </span>
                <span className="fs-12">
                  {removeCountryCode(departureThereToHere[0]?.departure_time)}
                </span>
                <span className="fs-12"> (Local time)</span>
              </Col>
              <Col md={6} className="text-center fs-14 mt-4 mt-md-0">
                <div
                  className="d-sm-flex align-items-center justify-content-center"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <span className="d-inline-flex align-items-center">
                    <img
                      src="/assets/img/clock.svg"
                      alt="clock"
                      className="img-fluid service-icon mr-1"
                    />
                    {timeCalc(arrivalElapsedTime)}
                    &nbsp; Duration
                  </span>

                  <span className="d-inline-flex align-items-center ">
                    &nbsp;
                    <span className="text-muted">|</span>
                    &nbsp;
                    <img
                      src="/assets/img/economy.svg"
                      alt=""
                      className="img-fluid service-icon mr-1"
                    />
                    {fly.passenger_infos[0].cabin_class == "Y"
                      ? "Economy"
                      : fly.passenger_infos[0].cabin_class == "C"
                      ? "Business"
                      : fly.passenger_infos[0].cabin_class == "S"
                      ? "Premium Economy"
                      : fly.passenger_infos[0].cabin_class == "J"
                      ? "Premium Business"
                      : fly.passenger_infos[0].cabin_class == "F"
                      ? "First Class"
                      : fly.passenger_infos[0].cabin_class == "P"
                      ? "Premium First"
                      : fly.passenger_infos[0].cabin_class}
                  </span>
                </div>
                <div className="two-dots my-3 text-muted position-relative border-top">
                  <span className="flight-service">
                    <img
                      src="/assets/img/airplane.svg"
                      alt=""
                      className="img-fluid service-icon service-icon_flight mr-1"
                    />
                    <span className="type-text px-2 position-relative">
                      Flight
                    </span>
                  </span>
                </div>
              </Col>
              <Col lg={3}>
                {
                  departureThereToHere[departureThereToHere.length - 1]
                    ?.arrival_airport
                }{" "}
                <span className="d-block">
                  {
                    departureThereToHere[departureThereToHere.length - 1]
                      ?.arrival_date
                  }
                </span>{" "}
                <span className="fs-12">
                  {removeCountryCode(
                    departureThereToHere[departureThereToHere.length - 1]
                      ?.departure_time
                  )}
                </span>
                <span className="fs-12"> (Local time)</span>
              </Col>
            </Row>
          </div>
        </>
      </div>
    );
  };

  return (
    <div>
      <Row className="flight_card" gap={2}>
        <Col
          xs={3}
          lg={2}
          // style={{
          //   borderRight: "1px solid #e5e5e5",
          //   height: "120px",
          // }}
        >
          {" "}
          <img className="flight_logo" src={fly?.airline_logo} alt="" />
          {/* <span
            style={{
              fontSize: "15px",
              color: "rgb(0, 0, 0)",
            }}
          >
            {fly?.gds}
          </span> */}
        </Col>
        <Col xs={9} lg={7} style={{}}>
          {fly.itinerary_leg_descs?.length == 1 ? (
            <div
              style={{
                padding: "10px ",
              }}
              className="card_border"
            >
              <span
                className="text-primary"
                style={{
                  fontSize: "12px",
                  color: "rgb(11, 72, 185)",
                }}
              >
                One way flights
              </span>
              <span
                style={{
                  fontSize: "18px",
                  color: "rgb(0, 0, 0)",
                  fontWeight: 600,
                  display: "block",
                }}
              >
                {fly?.origin_code} <FaArrowRightLong className="mx-2" />
                {fly?.destination_code}
              </span>
              <div className="d-flex align-items-center gap-4">
                {" "}
                <div className="d-flex align-items-center gap-2  my-2 ">
                  <LuPlaneTakeoff
                    size={20}
                    style={{
                      color: "#d93a47",
                    }}
                  />
                  <span>
                    <span className="fs-12 d-block fw-bold">Take off</span>{" "}
                    <span className="fw-bold d-block ">
                      {removeCountryCode(fly?.departure_time)} (Local time)
                    </span>
                    <span className="fs-12">{fly.origin_airport_name}</span>
                  </span>
                </div>{" "}
                <div className="d-flex align-items-center gap-2 ">
                  {" "}
                  <LuPlaneLanding
                    size={20}
                    style={{
                      color: "#d93a47",
                    }}
                  />
                  <span>
                    {" "}
                    <span className="fs-12 d-block fw-bold">Landing</span>
                    <span className="fw-bold d-block ">
                      {removeCountryCode(fly?.arrival_time)} (Local time)
                    </span>
                    <span className="fs-12">
                      {fly.destination_airport_name}
                    </span>
                  </span>
                </div>
                {/* <div className="d-flex align-items-center gap-2 ">
                      <span>
                        {" "}
                        {fly.schedules.length == 0 ? (
                          <>
                            <span className="fs-12 d-block fw-bold">
                              Non stop
                            </span>
                            <span className="fs-13">Direct flight</span>
                          </>
                        ) : (
                          <>
                            <span className="fs-12 d-block fw-bold">
                              {fly.schedules.length > 1
                                ? fly.schedules.length + " " + "Stops"
                                : fly.schedules.length + " " + "Stop"}
                            </span>
                            <span className="fs-13">Transit</span>
                          </>
                        )}
                      </span>
                    </div> */}
              </div>
              {/* <span>Flight Duration </span> */}
              <span className="text-black fs-13 ">
                {fly?.flight_duration}
              </span>{" "}
            </div>
          ) : (
            <>
              {generateComp(fly?.schedules)}
              {/* <div className="">
                    <>
                      <div
                        className=" round_trip_border"
                        style={{
                          borderLeft: "1px solid #e5e5e5",
                          borderRight: "1px solid #e5e5e5",
                          padding: "10px ",
                        }}
                      >
                        <Row>
                          <Col lg={3}>
                            {fly.itinerary_leg_descs[0][0]?.departure_location}
                            <span className="d-block">
                              {
                                fly.itinerary_leg_descs[0][0]
                                  ?.departure_datetime
                              }{" "}
                            </span>
                            <span className="fs-12"> (Local time)</span>
                          </Col>
                          <Col lg={6}>
                            <div className="d-flex justify-content-center">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-evenly",
                                  gap: "10px",
                                  flexDirection: "column",
                                  height: "100%",
                                  width: "100%",
                                }}
                              >
                                {" "}
                                <div>
                                  {" "}
                                  <span>
                                    {fly.itinerary_leg_descs[0][0]
                                      ?.stop_count == 0
                                      ? "Non stop"
                                      : fly.itinerary_leg_descs[0][0]
                                          ?.stop_count == 1
                                      ? "One stop"
                                      : "Multiple stop"}
                                  </span>
                                </div>
                                <div
                                  className="text-center"
                                  style={{
                                    width: "100%",
                                    position: "relative",
                                    zIndex: 100,
                                  }}
                                >
                                  Flight
                                  <div
                                    style={{
                                      position: "absolute",
                                      width: "100%",
                                      left: 0,
                                      top: 10,
                                      borderBottom: "1px solid #ddd",
                                      zIndex: -1,
                                    }}
                                  ></div>
                                </div>
                                <span>
                                  {" "}
                                  {fly.itinerary_leg_descs[0][0]?.duration
                                    ? timeCalc(
                                        fly.itinerary_leg_descs[0][0]?.duration
                                      )
                                    : fly?.flight_duration}
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col lg={3}>
                            {
                              fly.itinerary_leg_descs[0][
                                fly.itinerary_leg_descs[0].length - 1
                              ]?.arrival_location
                            }{" "}
                            <span className="d-block">
                              {
                                fly.itinerary_leg_descs[0][
                                  fly.itinerary_leg_descs[0].length - 1
                                ]?.arrival_datetime
                              }
                            </span>{" "}
                            <span className="fs-12"> (Local time)</span>
                          </Col>
                        </Row>
                      </div>



                      <div
                        className=" round_trip_border  "
                        style={{
                          borderLeft: "1px solid #e5e5e5",
                          borderRight: "1px solid #e5e5e5",
                          padding: "10px ",
                        }}
                      >
                        <Row>
                          <Col lg={3}>
                            {fly.itinerary_leg_descs[1][0]?.departure_location}
                            <span className="d-block">
                              {
                                fly.itinerary_leg_descs[1][0]
                                  ?.departure_datetime
                              }
                            </span>
                            <span className="fs-12"> (Local time)</span>
                          </Col>
                          <Col lg={6}>
                            <div className="d-flex justify-content-center">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-evenly",
                                  gap: "10px",
                                  flexDirection: "column",
                                  height: "100%",
                                  width: "100%",
                                }}
                              >
                                {" "}
                                <div>
                                  {" "}
                                  <span>
                                    {fly.itinerary_leg_descs[1][
                                      fly.itinerary_leg_descs[1].length - 1
                                    ]?.stop_count == 0
                                      ? "Non stop"
                                      : fly.itinerary_leg_descs[1][
                                          fly.itinerary_leg_descs[1].length - 1
                                        ]?.stop_count == 1
                                      ? "One stop"
                                      : "Multiple stop"}
                                  </span>
                                </div>

                                <div
                                  className="text-center"
                                  style={{
                                    width: "100%",
                                    position: "relative",
                                    zIndex: 100,
                                  }}
                                >
                                  Flight
                                  <div
                                    style={{
                                      position: "absolute",
                                      width: "100%",
                                      left: 0,
                                      top: 10,
                                      borderBottom: "1px solid #ddd",
                                      zIndex: -1,
                                    }}
                                  ></div>
                                </div>
                                <span>
                                  {" "}
                                  {fly.itinerary_leg_descs[1][
                                    fly.itinerary_leg_descs[1].length - 1
                                  ]?.duration
                                    ? timeCalc(
                                        fly.itinerary_leg_descs[1][
                                          fly.itinerary_leg_descs[1].length - 1
                                        ]?.duration
                                      )
                                    : fly?.flight_duration}
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col lg={3}>
                            {
                              fly.itinerary_leg_descs[1][
                                fly.itinerary_leg_descs[1].length - 1
                              ]?.arrival_location
                            }{" "}
                            <span className="d-block">
                              {
                                fly.itinerary_leg_descs[1][
                                  fly.itinerary_leg_descs[1].length - 1
                                ]?.arrival_datetime
                              }
                            </span>{" "}
                            <span className="fs-12"> (Local time)</span>
                          </Col>
                        </Row>
                      </div>
                    </>
                  </div> */}
            </>
          )}{" "}
        </Col>
        <Col
          lg={3}
          className="d-flex justify-content-center align-items-center"
        >
          <div className="d-flex flex-row flex-lg-column gap-3 gap-lg-0 align-items-center ">
            {" "}
            <span className="fare_text">
              {chargeDetails.commission_percentage == 0 ? (
                <span> ৳ {priceFormat(fly?.fare_details?.total_fare)}</span>
              ) : (
                <>
                  <del
                    className=""
                    style={{
                      color: "red",
                    }}
                  >
                    <span className="text-center d-block fare_text_discounted">
                      ৳ {priceFormat(fly?.fare_details?.total_fare)}
                    </span>
                  </del>
                  <span> ৳ {priceCalc(fly?.fare_details?.total_fare)}</span>
                </>
              )}
            </span>
            <div className="d-flex flex-row-reverse flex-lg-column gap-3 gap-lg-0 align-items-center">
              <button
                onClick={() =>
                  navigate("/booking", {
                    state: {
                      // ScheduleDescription: fly?.schedules,
                      // PassengerInfo: fly?.passenger_infos,
                      // TotalFare: fly?.fare_details?.total_fare,

                      // isRefundable: fly?.passenger_infos[0]?.refundable,
                      // LegDescription: fly?.leg_description,
                      // ID: fly?.id,
                      // MealCode: fly?.passenger_infos[0]?.meal_code,
                      // rpathname: "/search",

                      // currency: fly?.fare_details?.currency,
                      // _gds: fly?.gds,
                      // isRoundTrip: false,
                      flight: fly,
                      legDescription: legDescription,
                    },
                  })
                }
                className="book_btn"
              >
                Book now{" "}
              </button>
              <span
                style={{
                  cursor: "pointer ",
                }}
                onClick={() => handleDetails(index)}
              >
                <span className="text-center d-flex align-items-center gap-1 my-1 my-md-2">
                  {" "}
                  <span> Flight details</span>
                  <IoIosArrowDropdownCircle size={16} />
                </span>
              </span>
            </div>
          </div>
        </Col>

        <div className={`${tabIndex == index ? "d-block" : "d-none"}`}>
          <Tabs
            id={index}
            activeKey={tabKey}
            onSelect={(k) => setTabKey(k)}
            className="mb-3 mt-3"
          >
            <Tab eventKey="flight_info" title="Flight info">
              {/* {fly?.schedules.map((schedule) => (
                    <>
                      {schedule?.layover_time && (
                        <>
                          <div className="layover_time">
                            <p>{timeCalc(schedule?.layover_time)} Layover</p>
                          </div>
                        </>
                      )}
                      <Row
                        className="gap-4 gap-md-0"
                        style={{
                          border: "1px solid #e5e5e5",
                          padding: "20px 10px",
                          borderRadius: "5px",
                          marginBottom: "10px",
                        }}
                      >
                        <Col lg={7} gap={3}>
                          <Row className="gap-3 gap-md-0">
                            <Col
                              lg={5}
                              className="d-flex gap-4 justify-content-between justify-content-md-start"
                            >
                              <img
                                src={`https://pics.avs.io/200/200/${schedule?.operating_code}@2x.png`}
                                alt=""
                                className="flight_logo"
                              />
                              <div
                                className="text-center"
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                <p>{schedule?.operating_code}</p>
                                <span>{schedule?.operating_code}</span> -
                                <span> {schedule?.flight_number}</span>
                              </div>
                            </Col>
                            <Col
                              lg={7}
                              className="d-flex gap-5 justify-content-between justify-content-md-start "
                            >
                              <div
                                className="text-center d-flex flex-column"
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                <span>{schedule?.departure_airport}</span>

                                <span>
                                  {removeCountryCode(schedule?.departure_time)}{" "}
                                  (Local time)
                                </span>
                                <span>
                                  Terminal - {schedule?.departure_terminal}
                                </span>
                              </div>
                              <div
                                className="text-center d-flex flex-column "
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                <span>{schedule?.arrival_airport}</span>
                                <span>
                                  {removeCountryCode(schedule?.arrival_time)}{" "}
                                  (Local time)
                                </span>
                                <span>
                                  Terminal - {schedule?.arrival_terminal}
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={5}>
                          <div className="d-flex justify-content-center">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-evenly",
                                gap: "10px",
                                flexDirection: "column",
                                height: "100%",
                                width: "100%",
                              }}
                            >
                              {" "}
                              <div>
                                {" "}
                                <span>
                                  {schedule?.elapsed_time
                                    ? timeCalc(schedule?.elapsed_time)
                                    : fly?.flight_duration}
                                </span>
                                <span
                                  style={{
                                    margin: "0 5px",
                                    padding: "0 5px",
                                    borderRight: "1px solid #e5e5e5",
                                    borderLeft: "1px solid #e5e5e5",
                                  }}
                                >
                                  Meal-{" "}
                                </span>
                                <span>
                                  {fly?.passenger_infos[0]?.cabin_class}
                                </span>
                              </div>
                              <div
                                className="text-center"
                                style={{
                                  width: "100%",
                                  position: "relative",
                                  zIndex: 100,
                                }}
                              >
                                Flight
                                <div
                                  style={{
                                    position: "absolute",
                                    width: "100%",
                                    left: 0,
                                    top: 10,
                                    borderBottom: "1px solid #ddd",
                                    zIndex: -1,
                                  }}
                                ></div>
                              </div>
                              <div>20 kilogram</div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </>
                  ))} */}
              <div className="flight-info border rounded">
                {fly.schedules ? (
                  fly.schedules?.map((stop, index) => {
                    return (
                      <>
                        <div>
                          <Row className="row review-article my-3 align-items-center no-gutters position-relative">
                            <Col
                              sm={3}
                              lg={2}
                              className="text-sm-center mb-4 mb-sm-0 __image-name"
                            >
                              <div className="brand-img">
                                <img
                                  src={`https://pics.avs.io/200/200/${stop?.operating_code}@2x.png`}
                                  alt=""
                                  className="img-fluid _img"
                                />{" "}
                              </div>
                              <div className="airline-box">
                                <div className="fs-13 fs-13-1">
                                  {stop?.operating_code} - {stop?.flight_number}
                                </div>
                              </div>
                            </Col>
                            <Col sm={9} lg={10}>
                              <Row>
                                <Col
                                  md={3}
                                  sm={12}
                                  className="_vax text-center"
                                >
                                  <div className="fs-13 font-weight-500">
                                    {stop?.departure_airport}
                                  </div>
                                  <span className="fs-14 font-weight-400 d-block">
                                    {stop?.departure_date}{" "}
                                  </span>
                                  <span>
                                    {removeCountryCode(stop?.departure_time)}{" "}
                                    (Local time)
                                  </span>
                                  <span className=" fs-13 d-block">
                                    Terminal - {stop?.departure_terminal}
                                  </span>
                                  <span className="text-muted fs-13">
                                    {/* {airportNameFind(
                                          stop?.departure_airport
                                        )} */}
                                    {stop.departure_city}
                                  </span>
                                </Col>
                                {/* eslint-disable-next-line */}

                                <Col
                                  md={6}
                                  className="text-center fs-14 mt-4 mt-md-0"
                                >
                                  <div>
                                    {stop.layover_time !== 0 &&
                                    stop.layover_time !== "" ? (
                                      <span className="fs-11 layover">
                                        {timeCalc(stop.layover_time)} Layover
                                      </span>
                                    ) : null}
                                  </div>
                                  <div
                                    className="d-sm-flex align-items-center justify-content-center"
                                    style={{
                                      fontSize: "12px",
                                    }}
                                  >
                                    <span className="d-inline-flex align-items-center">
                                      <img
                                        src="/assets/img/clock.svg"
                                        alt="clock"
                                        className="img-fluid service-icon mr-1"
                                      />
                                      {timeCalc(stop?.elapsed_time)}
                                      &nbsp; Duration
                                      {/* {durationCalculator(
                                            dateNormalizer(
                                              stop.SegmentDetails.ArrivalTime
                                            ),
                                            dateNormalizer(
                                              stop.SegmentDetails.DepartureTime
                                            )
                                          )} */}
                                    </span>
                                    <span className="d-inline-flex align-items-center">
                                      &nbsp;
                                      <span className="text-muted">|</span>
                                      &nbsp;
                                      <img
                                        src="/assets/img/meal-1.svg"
                                        alt=""
                                        className="img-fluid service-icon mr-1"
                                      />
                                      {fly.passenger_infos[0].meal_code}
                                    </span>
                                    <span className="d-inline-flex align-items-center ">
                                      &nbsp;
                                      <span className="text-muted">|</span>
                                      &nbsp;
                                      <img
                                        src="/assets/img/economy.svg"
                                        alt=""
                                        className="img-fluid service-icon mr-1"
                                      />
                                      {fly.passenger_infos[0].cabin_class == "Y"
                                        ? "Economy"
                                        : fly.passenger_infos[0].cabin_class ==
                                          "C"
                                        ? "Business"
                                        : fly.passenger_infos[0].cabin_class ==
                                          "S"
                                        ? "Premium Economy"
                                        : fly.passenger_infos[0].cabin_class ==
                                          "J"
                                        ? "Premium Business"
                                        : fly.passenger_infos[0].cabin_class ==
                                          "F"
                                        ? "First Class"
                                        : fly.passenger_infos[0].cabin_class ==
                                          "P"
                                        ? "Premium First"
                                        : fly.passenger_infos[0].cabin_class}
                                    </span>
                                  </div>
                                  <div className="two-dots my-3 text-muted position-relative border-top">
                                    <span className="flight-service">
                                      <img
                                        src="/assets/img/airplane.svg"
                                        alt=""
                                        className="img-fluid service-icon service-icon_flight mr-1"
                                      />
                                      <span className="type-text px-2 position-relative">
                                        Flight
                                      </span>
                                    </span>
                                  </div>

                                  <div className="d-sm-flex align-items-center justify-content-center">
                                    {fly?.refundable ? (
                                      <span className="text-success">
                                        Refundable
                                      </span>
                                    ) : (
                                      <span className="text-danger">
                                        Non-Refundable
                                      </span>
                                    )}
                                  </div>
                                </Col>
                                <Col
                                  md={3}
                                  sm={12}
                                  className="_vax text-center"
                                >
                                  <div className="fs-13 font-weight-500">
                                    {stop?.arrival_airport}
                                  </div>
                                  <span className="fs-14 font-weight-400 d-block">
                                    {stop?.arrival_date}{" "}
                                  </span>
                                  <span>
                                    {removeCountryCode(stop?.arrival_time)}{" "}
                                    (Local time)
                                  </span>
                                  <span className=" fs-13 d-block">
                                    Terminal - {stop?.arrival_terminal}
                                  </span>
                                  <span className="text-muted fs-13">
                                    {/* {airportNameFind(stop?.arrival_airport)} */}
                                    {stop.arrival_city}
                                  </span>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          {index === fly.schedules.length - 1 ? null : (
                            <div className="separator position-relative d-flex align-items-center justify-content-between my-3">
                              <span className="tri-right"></span>
                              <span className="tri-left"></span>
                            </div>
                          )}

                          {/* {index === fly.schedules.length - 1 ? null : (
                                <div className="separator position-relative d-flex align-items-center justify-content-between my-3">
                                  <span className="tri-right"></span>
                                  <span className="tri-left"></span>
                                </div>
                              )} */}

                          {/* {(() => {
                                if (stop.SegmentDetails.DepartureTime.split('T')[0] != date) {
                                  
                                }
                              })()} */}
                          {/* {isRoundTrip &&
                          stop.SegmentDetails.Destination === to && (
                            <div className="separator position-relative d-flex align-items-center justify-content-between my-3">
                              <span className="tri-right"></span>
                              <span className="fs-11 layover">
                                {stop.SegmentDetails.Destination !== to
                                  ? layovers[index]
                                  : `↓↓↓ ${date.split("T")[0]} ↓↓↓`}
                              </span>
                              <span className="tri-left"></span>
                            </div>
                          )}
                        {index ===
                        data["air:Option"][0]["air:BookingInfo"].length -
                          1 ? null : (
                          <div className="separator position-relative d-flex align-items-center justify-content-between my-3">
                            <span className="tri-right"></span>
                            <span className="fs-11 layover">
                              {stop.SegmentDetails.Destination !== to
                                ? layovers[index + hox]
                                : `↓↓↓ ${date.split("T")[0]} ↓↓↓`}
                            </span>
                            <span className="tri-left"></span>
                          </div>
                        )} */}
                        </div>
                      </>
                    );
                  })
                ) : (
                  <div>
                    <h2>Not found </h2>
                  </div>
                )}
              </div>
            </Tab>

            <Tab eventKey="fare_details" title="Fare details ">
              <div
                style={{
                  overflowX: "auto",
                  maxWidth: "80vw",
                }}
              >
                <table className="table table-bordered">
                  <thead>
                    <tr className="text-center">
                      <th scope="col">Passenger type</th>
                      <th scope="col">Base fare</th>
                      <th scope="col">Tax fare</th>
                      <th scope="col">Total fare </th>
                    </tr>
                  </thead>
                  <tbody>
                    {fly?.passenger_infos.map((passenger) => (
                      <tr className="text-center">
                        <td>
                          {passenger?.passenger_number +
                            " " +
                            passenger?.passenger_type}
                        </td>
                        <td>
                          {passenger.base_fare * passenger?.passenger_number} (
                          {passenger?.currency})
                        </td>
                        <td>
                          {passenger?.tax_fare * passenger?.passenger_number} (
                          {passenger?.currency})
                        </td>
                        <td>
                          {passenger?.total_fare * passenger?.passenger_number}{" "}
                          ({passenger?.currency})
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Tab>
            <Tab eventKey="baggage" title="Baggage">
              <Row>
                <Col
                  md={6}
                  style={{
                    overflowX: "auto",
                    maxWidth: "80vw",
                  }}
                >
                  <table className="table table-bordered">
                    <thead>
                      <tr className="text-center fs-14">
                        <th scope="col">Passenger type</th>
                        <th scope="col">Weight</th>
                        <th scope="col">Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fly?.passenger_infos.map((passenger) => (
                        <tr className="text-center">
                          <td>{passenger?.passenger_type}</td>
                          <td>
                            {passenger?.baggage_info?.weight +
                              " " +
                              passenger?.baggage_info?.unit}
                          </td>
                          <td>{""}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Col>
                <Col md={6}>
                  <p className="mb-0">
                    The baggage information is just for reference. Please Check
                    with airline before check-in. For more information, visit
                    Airlines Website.
                  </p>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="cancellation" title="Cancellation">
              <div
                style={{
                  borderBottom: "1px solid #e5e5e5",
                }}
              >
                <div
                  style={{
                    overflowX: "auto",
                    maxWidth: "80vw",
                  }}
                >
                  <table className="table table-bordered table-responsive">
                    <thead>
                      <tr className="text-center fs-14">
                        <th scope="col">Passenger type</th>
                        <th scope="col">Non refundable</th>
                        <th scope="col">Class</th>
                        <th scope="col">Available seats </th>
                        <th scope="col">Booking code </th>
                        <th scope="col">Meal code </th>
                      </tr>
                    </thead>
                    <tbody>
                      {fly?.passenger_infos.map((passenger) => (
                        <tr className="text-center">
                          <td>{passenger?.passenger_type}</td>
                          <td>{passenger?.refundable}</td>
                          <td>{passenger?.cabin_class}</td>
                          <td>{passenger?.available_seats}</td>
                          <td>{passenger?.booking_code}</td>
                          <td>{passenger?.meal_code}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <h6>Terms & Conditions</h6>
                <div
                  style={{
                    overflowX: "auto",
                    maxWidth: "80vw",
                  }}
                >
                  <table className="table table-bordered table-responsive">
                    <thead>
                      <tr className="text-center fs-12 ">
                        <th scope="col" className="fw-normal">
                          Type
                        </th>
                        <th scope="col" className="fw-normal">
                          Changeable before departure
                        </th>
                        <th scope="col" className="fw-normal">
                          Penalty
                        </th>
                        <th scope="col" className="fw-normal">
                          Changeable after departure
                        </th>
                        <th scope="col" className="fw-normal">
                          Penalty
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>

              {/* {fareRules} */}

              <br />

              <h6>Terms & Conditions</h6>
              <ul>
                <li>
                  The charges are per passenger per sector and applicable only
                  on refundable type tickets.
                </li>
                <li>
                  Rescheduling Charges = Rescheduling/Change Penalty + Fare
                  Difference (if applicable)
                </li>
                <li>
                  Partial cancellation is not allowed on tickets booked under
                  special discounted fares.
                </li>
                <li>
                  In case of no-show or ticket not cancelled within the
                  stipulated time, only statutory taxes are refundable subject
                  to Goibibo Service Fee.
                </li>
                <li>No Baggage Allowance for Infants</li>
                <li>
                  In case of restricted cases , no amendments /cancellation
                  allowed.
                </li>
                <li>
                  Airline penalty needs to be reconfirmed prior to any
                  amendments or cancellation.
                </li>
                <li>
                  Disclaimer: Airline Penalty changes are indicative and can
                  change without prior notice
                </li>
                <li>
                  NA means Not Available. Please check with airline for penalty
                  information.
                </li>
                <li>
                  If taxes are more than default cancellation penalty then all
                  taxes will be refundable.
                </li>
              </ul>
            </Tab>
          </Tabs>
        </div>
      </Row>
    </div>
  );
}

export default FlightCardComp;
